<template>
  <div>
    <SystemHead page-type="sign" />
    <div class="form-signup mb-5 mx-auto form-content">
      <h4>{{ $t("sign.new_pass") }}</h4>
      <el-form
        :model="formData"
        :rules="rules"
        ref="newPasswordForm"
        :disabled="disabled"
      >
        <div class="row">
          <div class="form-group col-md-6">
            <label class="email-label">{{ $t("sign.email") }}</label>
            <div class="email-text">{{ email }}</div>
          </div>
          <div class="col-md-6">
            <el-form-item :label="$t('sign.veri_code') + '：'" prop="code">
              <el-input v-model="formData.code"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <el-form-item :label="$t('sign.password') + '：'" prop="password">
              <el-input v-model="formData.password"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item
              :label="$t('sign.re_enter_pass') + '：'"
              prop="repassword"
            >
              <el-input v-model="formData.repassword"></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <button
        :disabled="disabled"
        type="button"
        class="btn btn-primary"
        @click="submitForm('newPasswordForm')"
      >
        {{ $t("save") }}
      </button>
    </div>
    <footer class="footer mt-auto py-2 fixed-bottom text-center">
      <div class="container">
        <small class="d-block text-muted"
          >@2019 Brainlabel.org. All rights reserved.</small
        >
      </div>
    </footer>
  </div>
</template>

<script>
import SystemHead from "../../layouts/systemHead";
import { login } from "../../api/api";
import { MessageBox, Loading } from "element-ui";

export default {
  components: {
    SystemHead,
  },
  data() {
    return {
      email: this.$route.query.email,
      formData: {
        code: "",
        password: "",
        repassword: "",
      },

      disabled: false,
    };
  },
  computed: {
    rules() {
      return {
        code: [{ required: true, message: " ", trigger: "change" }],
        password: [{ required: true, message: " ", trigger: "change" }],
        repassword: [{ required: true, message: " ", trigger: "change" }],
      };
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleSubmit();
        } else {
          return false;
        }
      });
    },
    async handleSubmit() {
      this.disabled = true;
      let submitData = {
        email: this.email,
        code: this.formData.code,
        password: this.formData.password,
        repassword: this.formData.repassword,
        type: "forgotreset",
      };
      let _self = this;
      try {
        let res = await login(submitData);
        if (res.status === 200) {
          this.$helper.successNotify(
            res.message,
            function () {
              _self.$router.push("/");
            },
            1000
          );
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        console.log(error.message);
        this.$helper.errNotify(error.message);
      } finally {
        setTimeout(() => {
          this.disabled = false;
        }, 1000);
      }
    },
  },
};
</script>

<style lang="less">
.form-content {
  label {
    margin-bottom: 0;
  }
  .el-form-item__label {
    line-height: 30px;
  }
  .email-label {
    height: 30px;
    line-height: 30px;
  }
  // .email-text{
  //   height: 40px;
  //   line-height: 40px;
  // }
  .email-label,
  .email-text {
    font-size: 14px;
  }
}
</style>
